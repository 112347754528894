<template>
  <b-card
    class="warehousestatement-edit-wrapper"
  >
    <!-- form -->
    <b-form id="warehousestatementForm" class="edit-form mt-2">
      <b-row>
<!--        <b-col md="3">-->
<!--          <b-form-group-->
<!--            label-cols="3"-->
<!--            label-cols-lg="3"-->
<!--            label="终端客户"-->
<!--            label-for="company_name"-->
<!--            label-size="sm"-->
<!--            class="mb-1 required"-->
<!--          >-->
<!--            <b-form-input-->
<!--              id="company_name"-->
<!--              size="sm"-->
<!--              v-model="warehousestatement.company_name"-->
<!--              v-b-modal.modal-select-company-->
<!--              placeholder="点击搜索终端客户"-->
<!--            />-->
<!--          </b-form-group>-->
<!--        </b-col>-->
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="结算方式"
            label-for="statement_type"
            label-size="sm"
            class="mb-1 required"
          >
            <v-select
                id="statement_type"
                :options="getCodeOptions('statement_type')"
                :clearable="true"
                v-model="statementType"
                @input="changeSelect('statement_type',$event)"
                class="select-size-sm"
                placeholder="请选择结算方式"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="结算日期"
            label-for="statement_day"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="statement_day"
              size="sm"
              type="number"
              v-model="warehousestatement.statement_day"
              placeholder="次月几日"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="到账周期"
            label-for="statement_over"
            label-size="sm"
            class="mb-1 required"
          >
            <b-form-input
              id="statement_over"
              size="sm"
              type="number"
              v-model="warehousestatement.statement_over"
              placeholder="天"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
              <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="付款周期"
                      label-for="pay_period"
                      label-size="sm"
                      class="mb-1 required"
              >
                  <v-select
                          id="pay_period"
                          :options="getCodeOptions('preorder_pay_preiod')"
                          :clearable="true"
                          v-model="preorderPayPreiod"
                          @input="changeSelect('pay_period',$event)"
                          class="select-size-sm"
                          placeholder="请选择付款周期"
                  />
              </b-form-group>
          </b-col>



                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
    <b-modal
        id="modal-select-company"
        ok-only
        ok-title="确认"
        @ok="onSelectCompany"
        cancel-title="取消"
        centered
        size="lg"
        title="选择终端客户"
    >
      <company-select
          ref="companySelect" :companyType="'OffSalesChannelCustomer'"
      >
      </company-select>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import warehousestatementStore from './warehousestatementStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import CompanySelect from "@/views/apps/company/CompanySelect";

export default {
  components: {
    CompanySelect,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      warehousestatement: ref({}),
      statementType:{},
        preorderPayPreiod:"",

    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('warehousestatement')) store.registerModule('warehousestatement', warehousestatementStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('warehousestatement')) store.unregisterModule('warehousestatement')
    })

    const edit = function() {
      store.dispatch('warehousestatement/edit', {id: this.id}).then(res => {
        this.warehousestatement = res.data.data
        this.preorderPayPreiod = getCodeLabel("preorder_pay_preiod",this.warehousestatement.pay_period)
        this.statementType = getCode("statement_type", this.warehousestatement.statement_type)
      })
    }

    const view = function() {
      store.dispatch('warehousestatement/view', {id: this.id}).then(res => {
        this.warehousestatement = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      if (this.warehousestatement.company_id==null){
        this.warehousestatement.company_id = this.companyId
        this.warehousestatement.company_name = getCodeLabel("company",this.companyId)
      }
      const cRegExp = /^[1-9]\d*$/
      if (this.warehousestatement.statement_type == undefined || this.warehousestatement.statement_type == "") {
        toast.error("请选择 结算方式!")
        return false
      }
      if (this.warehousestatement.statement_type == 2) {
        if (this.warehousestatement.statement_day == undefined || this.warehousestatement.statement_day == "") {
          toast.error("请选择 结算日期!")
          return false
        }
        if (!cRegExp.test(this.warehousestatement.statement_day)) {
          toast.error('结算日期必须是正整数!')
          return false
        }
        if (this.warehousestatement.statement_day>28) {
          toast.error('结算日期必须小于28!')
          return false
        }
      }

      if (this.warehousestatement.statement_over == undefined || this.warehousestatement.statement_over == "") {
        toast.error("请选择 到账周期!")
        return false
      }

        if (this.warehousestatement.pay_period == undefined || this.warehousestatement.pay_period == "") {
            toast.error("请选择 付款周期!")
            return false
        }

      if (!cRegExp.test(this.warehousestatement.statement_over)) {
        toast.error('到账周期必须是正整数!')
        return false
      }
      console.log(this.warehousestatement)


      store.dispatch('warehousestatement/save', this.warehousestatement).then(res => {
        if(res.data.code==0){
          toast.success('数据已保存!')
          // this.$router.push({ name: 'apps-warehousestatement-list',query:{id:this.warehouseId,companyId:}});
        }else{
          toast.error(res.data.data)
        }
      })
    }

    const onSelectCompany = function () {
      let checked = this.$refs.companySelect.getSelected()[0];
      this.warehousestatement.company_id = checked.company_id
      this.warehousestatement.company_name = checked.company_name
      this.$forceUpdate()
    }

    const changeSelect = function (key, event) {
      this.warehousestatement[key] = event==null?null:event.value
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      onSelectCompany,
      changeSelect,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.companyId = this.$route.query.companyId || null;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
